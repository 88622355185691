const GrowthSuiteDemo = () => import('vue-components/growth-suite/demo/GrowthSuiteDemo');
const LandingPage = () => import('vue-components/growth-suite/demo/LandingPage');

const Report = () => import('pages/org/_orgSlug/reports/new');
const SelectTemplate = () => import('pages/org/_orgSlug/reports/new/select-template');
const BuildReport = () => import('pages/org/_orgSlug/reports/__components__/BuildReport');
const CustomizeReport = () => import('pages/org/_orgSlug/reports/__components__/CustomizeReport');
const ReportContents = () => import('pages/org/_orgSlug/reports/__components__/ReportContents');
const ScheduleReport = () => import('pages/org/_orgSlug/reports/__components__/ScheduleReport');
const ReportSuccess = () => import('vue-components/growth-suite/demo/ReportSuccess');

const Dashboard = () => import('pages/org/_orgSlug/dashboard/index');

const SiteList = () => import('pages/sites');
const SitesGoogleAnalytics = () => import('pages/sites-google-analytics');
const SitesStats = () => import('pages/sites-stats');
const SitesPlugins = () => import('pages/sites-plugins');
const SitesManagedPlugins = () => import('pages/sites-managed-plugin-updates');
const SitesOptions = () => import('pages/sites-options');

const Services = () => import('pages/org/_orgSlug/services/new');
const Setup = () => import('pages/org/_orgSlug/services/new/index');
const ConfigureTiers = () => import('pages/org/_orgSlug/services/new/configure-tiers');
const Review = () => import('pages/org/_orgSlug/services/new/review');
const ServiceSuccess = () => import('vue-components/growth-suite/demo/ServiceSuccess');

const Invoice = () => import('vue-components/invoice/Invoice');
const SetUpClient = () => import('vue-components/invoice/SetUpClient');
const BillingInformation = () => import('vue-components/invoice/BillingInformation');
const InvoicePreview = () => import('vue-components/invoice/Preview');
const InvoiceDetails = () => import('vue-components/invoice/InvoiceDetails');
const InvoiceSuccess = () => import('vue-components/growth-suite/demo/InvoiceSuccess');

const Clients = () => import('pages/org/_orgSlug/clients/index');
const ClientsEmails = () => import('pages/org/_orgSlug/emails/index');
const ClientsReports = () => import('pages/org/_orgSlug/reports/index');

const Client = () => import('vue-components/growth-suite/demo/Client');
const ClientEmails = () => import('pages/org/_orgSlug/clients/_clientSlug/emails/index');
const ClientReports = () => import('pages/org/_orgSlug/clients/_clientSlug/reports/index');
const ClientShow = () => import('pages/org/_orgSlug/clients/_clientSlug/index');
const ClientEdit = () => import('pages/org/_orgSlug/clients/_clientSlug/edit');
const ClientPaymentMethods = () => import('pages/org/_orgSlug/clients/_clientSlug/payment_methods/index');

const ComingSoon = () => import('vue-components/growth-suite/demo/ComingSoon');

const defineReportChildrenRoutes = (rootRouteName) => {
	return [
		{
			name: `${rootRouteName}/reports/select-template`,
			path: '',
			component: SelectTemplate,
			meta: {
				rootRouteName
			}
		},
		{
			path: `${rootRouteName}/build/:token?`,
			component: BuildReport,
			children: defineBuildReportChildrenRoutes(rootRouteName),
			meta: {
				rootRouteName
			}
		}
	];
};

const defineBuildReportChildrenRoutes = (rootRouteName) => {
	return [
		{
			name: `${rootRouteName}/reports/customize`,
			path: 'customize',
			component: CustomizeReport,
			meta: {
				depth: 1,
				rootRouteName
			}
		},
		{
			name: `${rootRouteName}/reports/contents`,
			path: 'contents',
			component: ReportContents,
			meta: {
				depth: 2,
				rootRouteName
			}
		},
		{
			name: `${rootRouteName}/reports/schedule`,
			path: 'schedule',
			component: ScheduleReport,
			meta: {
				depth: 3,
				rootRouteName
			}
		}
	];
};

const defineServicesChildrenRoutes = (rootRouteName) => {
	return [
		{
			path: '',
			name: `${rootRouteName}-services-new`,
			component: Setup,
			meta: {
				depth: 1
			}
		},
		{
			path: 'configure-tiers',
			name: `${rootRouteName}-services-configure-tiers`,
			component: ConfigureTiers,
			meta: {
				depth: 2
			}
		},
		{
			path: 'review',
			name: `${rootRouteName}-services-review`,
			component: Review,
			meta: {
				depth: 3
			}
		}
	];
};

const defineInvoicesChildrenRoutes = (rootRouteName) => {
	return [
		{
			path: '',
			name: `${rootRouteName}/set-up-client`,
			component: SetUpClient,
			meta: {
				depth: 1
			}
		},
		{
			path: 'billing-information',
			name: `${rootRouteName}/billing-information`,
			component: BillingInformation,
			meta: {
				depth: 2
			}
		},
		{
			path: 'details',
			name: `${rootRouteName}/details`,
			component: InvoiceDetails,
			meta: {
				depth: 3
			}
		},
		{
			path: 'preview',
			name: `${rootRouteName}/preview`,
			component: InvoicePreview,
			meta: {
				depth: 4
			}
		},
		{
			path: 'success',
			name: `${rootRouteName}/success`,
			component: InvoiceSuccess
		}
	];
};

const defineClientChildrenRoutes = (rootRouteName) => {
	return [
		{
			path: '',
			name: `${rootRouteName}/client-show`,
			component: ClientShow
		}
	];
};

const defineDemoFeaturePaths = (rootRouteName, childRouteName, isWpe) => {
	return [
		{
			path: '',
			name: `${rootRouteName}-suite`,
			component: LandingPage,
			props: () => ({
				wpe: isWpe
			})
		},
		{
			path: `/${rootRouteName}/reporting`,
			component: Report,
			children: defineReportChildrenRoutes(childRouteName)
		},
		{
			name: `${rootRouteName}-report-success`,
			path: `/${rootRouteName}/report-success`,
			component: ReportSuccess
		},
		{
			path: `/${rootRouteName}/dashboard/:orgSlug`,
			name: `${rootRouteName}-dashboard`,
			component: Dashboard,
			props: true
		},
		{
			path: `/${rootRouteName}/sites`,
			name: `${rootRouteName}-sites`,
			component: SiteList
		},
		{
			path: `/${rootRouteName}/sites/google-analytics`,
			name: `${rootRouteName}-sites-google-analytics`,
			component: SitesGoogleAnalytics
		},
		{
			path: `/${rootRouteName}/sites/stats`,
			name: `${rootRouteName}-sites-stats`,
			component: SitesStats
		},
		{
			path: `/${rootRouteName}/sites/plugins`,
			name: `${rootRouteName}-sites-plugins`,
			component: SitesPlugins
		},
		{
			path: `/${rootRouteName}/sites/managed-plugins`,
			name: `${rootRouteName}-sites-managed-plugins`,
			component: SitesManagedPlugins
		},
		{
			path: `/${rootRouteName}/sites/options`,
			name: `${rootRouteName}-sites-options`,
			component: SitesOptions
		},
		{
			path: `/${rootRouteName}/services`,
			component: Services,
			children: defineServicesChildrenRoutes(rootRouteName)
		},
		{
			path: `/${rootRouteName}/services/success`,
			component: ServiceSuccess
		},
		{
			path: `/${rootRouteName}/subscriptions-and-billing`,
			component: Invoice,
			children: defineInvoicesChildrenRoutes(childRouteName),
			props: () => ({
				isEditPage: false,
				orgSlug: 'demo'
			})
		},
		{
			path: `/${rootRouteName}/clients`,
			component: Clients
		},
		{
			path: `/${rootRouteName}/clients/emails`,
			component: ClientsEmails
		},
		{
			path: `/${rootRouteName}/clients/reports`,
			component: ClientsReports
		},
		{
			path: `/${rootRouteName}/clients/:clientSlug`,
			component: Client,
			children: defineClientChildrenRoutes(childRouteName)
		},
		{
			path: `/${rootRouteName}/clients/:clientSlug/emails`,
			component: ClientEmails
		},
		{
			path: `/${rootRouteName}/clients/:clientSlug/reports`,
			component: ClientReports
		},
		{
			path: `/${rootRouteName}/clients/:clientSlug/edit/:orgSlug`,
			component: ClientEdit,
			props: { edit: true }
		},
		{
			path: `/${rootRouteName}/clients/:clientSlug/payment-methods`,
			component: ClientPaymentMethods
		},
		{
			path: `/${rootRouteName}/portal`,
			name: `${rootRouteName}-portal`,
			component: ComingSoon
		}
	];
};

export default [
	{
		path: '/try-growth-suite',
		component: GrowthSuiteDemo,
		children: defineDemoFeaturePaths('try-growth-suite', 'demo', false),
		props: () => ({
			wpe: false
		})
	},
	{
		path: '/try-flywheel-growth-suite',
		component: GrowthSuiteDemo,
		children: defineDemoFeaturePaths('try-flywheel-growth-suite', 'demo-wpe', true),
		props: () => ({
			wpe: true
		})
	}
];
