const Invoice = () => import('vue-components/invoice/Invoice');
const SetUpClient = () => import('vue-components/invoice/SetUpClient');
const BillingInformation = () => import('vue-components/invoice/BillingInformation');
const InvoiceDetails = () => import('vue-components/invoice/InvoiceDetails');
const InvoicePreview = () => import('vue-components/invoice/Preview');
const InvoiceProrate = () => import('vue-components/invoice/Prorate');
const InvoiceSuccess = () => import('vue-components/invoice/Success');

const buildInvoiceChildrenRoutes = (rootRouteName) => {
	return [
		{
			name: `${rootRouteName}/set-up-client`,
			path: '',
			component: SetUpClient,
			meta: {
				depth: 1
			}
		},
		{
			name: `${rootRouteName}/billing-information`,
			path: 'billing-information',
			component: BillingInformation,
			meta: {
				depth: 2
			}
		},
		{
			name: `${rootRouteName}/details`,
			path: 'details',
			component: InvoiceDetails,
			meta: {
				depth: 3
			}
		},
		{
			name: `${rootRouteName}/preview`,
			path: 'preview',
			component: InvoicePreview,
			meta: {
				depth: 4
			}
		},
		{
			path: 'prorate',
			component: InvoiceProrate
		},
		{
			path: 'success',
			component: InvoiceSuccess
		}
	];
};

export default [
	{
		path: '/org/:orgSlug/invoice/new',
		component: Invoice,
		props: (route) => ({
			isEditPage: false,
			...route.params
		}),
		children: buildInvoiceChildrenRoutes('invoice')
	},
	{
		path: '/org/:orgSlug/clients/:clientSlug/invoice/new',
		component: Invoice,
		props: (route) => ({
			isEditPage: false,
			...route.params
		}),
		children: buildInvoiceChildrenRoutes('client-invoice')
	},
	{
		path: '/org/:orgSlug/clients/:clientSlug/invoice/:invoicedRecordToken/edit',
		component: Invoice,
		props: (route) => ({
			isEditPage: true,
			...route.params
		}),
		children: buildInvoiceChildrenRoutes('edit-invoice')
	}
];
