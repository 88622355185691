<template>
	<router-view></router-view>
</template>

<script>
import Vue from 'vue';
import router from 'router';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'en'
});

export default {
	i18n,
	router
};
</script>
