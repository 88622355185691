const CreateDomain = () => import('actions/site/domains/CreateDomain');
const Step1 = () => import('actions/site/domains/Step1');
const Step2 = () => import('actions/site/domains/Step2');
const Step3 = () => import('actions/site/domains/Step3');

const buildDomainChildrenRoutes = (rootRouteName) => {
	return [
		{
			name: `${rootRouteName}/new`,
			path: 'new',
			component: Step1,
			meta: {
				depth: 1
			}
		},
		{
			name: `${rootRouteName}/configure-dns`,
			path: ':domainUrl/configure-dns',
			component: Step2,
			meta: {
				depth: 2
			}
		},
		{
			name: `${rootRouteName}/configure-www`,
			path: ':domainUrl/configure-www',
			component: Step2,
			meta: {
				depth: 3,
			}
		},
		{
			name: `${rootRouteName}/review-domain`,
			path: ':domainUrl/review',
			component: Step3,
			meta: {
				depth: 3
			}
		}
	];
};

export default [
	{
		path: '/:username/:siteSlug/domains/',
		component: CreateDomain,
		children: buildDomainChildrenRoutes('user')
	},
	{
		path: '/:org/:orgSlug/:siteSlug/domains/',
		component: CreateDomain,
		children: buildDomainChildrenRoutes('org')
	}
];
