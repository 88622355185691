import AutoRoutes from 'vue-auto-routing';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { preloadData } from 'helpers/preloaded';

Vue.use(VueRouter);

import domainRoutes from './domain';
import invoiceRoutes from './invoice';
import demoRoutes from './demo';

const demo = preloadData().growth_suite_demo;

const baseRoutes = [];
let routes = baseRoutes.concat(domainRoutes, invoiceRoutes);

if (demo) { routes = [...routes, ...demoRoutes] }

const router = new VueRouter({
	mode: 'history',
	routes: [...AutoRoutes, ...routes],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				selector: to.hash
			};
		}

		return { x: 0, y: 0 };
	}
});

export default router;